<template>
  <div
    ref="autocompleteElement"
    :aria-expanded="false"
    :aria-owns="slugify(label) + '-listbox'"
    aria-haspopup="listbox"
    class="AiAutocomplete"
    role="combobox"
    @keydown.prevent.down="onArrowDown"
    @keydown.prevent.up="onArrowUp"
    @keydown.esc="closeSuggestions">
    <ai-field-input
      ref="input"
      v-model="value"
      :active-descendant="activedescendant"
      :aria-auto-complete="'list'"
      :aria-controls="slugify(label) + '-listbox'"
      :aria-role="'searchbox'"
      :auto-complete="autoComplete"
      :disabled="disabled"
      :errors="errors"
      :icon-right-clickable="iconClickable"
      :inverse="inverse"
      :label="label"
      :name="slugify(label)"
      :placeholder="placeholder"
      type="text"
      @focus="onFocus"
      @keydown.enter="$emit('keydown.enter')">
      <template #right>
        <ai-icon
          :class="{
            'AiAutocomplete-fieldIcon--clearable': Boolean(value),
          }"
          :name="iconName as GenericIconName"
          :size="18"
          class="AiAutocomplete-fieldIcon"
          @click="onIconClick" />
      </template>

      <template v-if="message" #helper>
        <ai-typo as="span" variant="legal-regular">
          {{ message }}
        </ai-typo>
      </template>
    </ai-field-input>

    <div
      v-if="
        !hideSuggestions &&
        suggestionsOpened &&
        suggestions &&
        suggestions.length > 0 &&
        !disabled
      "
      :id="slugify(label) + '-listbox'"
      ref="suggestionsElement"
      :class="{ 'AiAutocomplete-suggestions--openTop': suggestionsTop }"
      class="AiAutocomplete-suggestions"
      role="listbox">
      <slot name="suggestion-header" />
      <div
        v-for="(suggestion, index) in suggestions"
        :id="getId(index)"
        :key="suggestion.key"
        :aria-selected="isSelected(index)"
        class="AiAutocomplete-suggestion"
        role="option"
        @keyup.enter.prevent="setResult(suggestion)">
        <component
          :is="suggestionComponent"
          v-if="suggestionComponent"
          :search="value"
          v-bind="suggestion.props"
          @click="setResult(suggestion)" />

        <ai-typo v-else as="span" variant="legal-regular">
          {{ suggestion.value }}
        </ai-typo>
      </div>
    </div>
    <div
      v-else-if="
        !hideSuggestions &&
        suggestionsOpened &&
        !disabled &&
        $slots['suggestion-empty-placeholder']
      "
      class="AiAutocomplete-suggestions">
      <slot name="suggestion-empty-placeholder" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import throttle from 'lodash/throttle';
import type { Component } from 'vue';

import { slugify } from '~/helpers';

import AiFieldInput from '../AiForm/AiFieldInput.vue';
import { GenericIconName, IconName } from '../../atoms/AiIcon/types';

import { AiSuggestion } from './interfaces';

type Props = {
  autoComplete?: 'off' | undefined;
  disabled?: boolean;
  errors?: string[];
  hideSuggestions?: boolean;
  iconClickable?: boolean;
  iconName?: IconName;
  inverse?: boolean;
  label: string;
  message?: string;
  modelValue: string | number | boolean | Record<string, unknown>;
  placeholder?: string;
  suggestionComponent?: Component;
  suggestions: AiSuggestion[] | null;
  suggestionsOpened: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  activedescendant: '',
  autoComplete: undefined,
  errors: undefined,
  hideSuggestions: false,
  iconClickable: false,
  iconName: undefined,
  message: undefined,
  placeholder: undefined,
  suggestionComponent: undefined,
  suggestionsOpened: true,
});

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
  (event: 'update:suggestions-opened', value: Props['suggestionsOpened']): void;
  (event: 'suggestionPicked', value: AiSuggestion): void;
  (event: 'focus'): void;
  (event: 'blur'): void;
  (event: 'icon-click'): void;
  (event: 'keydown.enter'): void;
};

const emits = defineEmits<Emits>();

const value = computed({
  get() {
    return props.modelValue as string;
  },
  set(newValue) {
    emits('update:modelValue', newValue);
  },
});

const emitSuggestionsOpened = 'update:suggestions-opened';

const autocompleteElement = ref<HTMLDivElement>();
const suggestionsElement = ref<HTMLDivElement>();
const input = ref<typeof AiFieldInput>();
const suggestionsTop = ref<boolean>();
const suggestions = computed(() => props.suggestions ?? []);

watch(suggestionsElement, () => {
  positionSuggestions();
});

function onFocus() {
  emits(emitSuggestionsOpened, true);
  emits('focus');
}

onMounted(() => {
  autocompleteElement.value?.addEventListener('focusout', onBlur);
  if (process.client) {
    document.addEventListener('scroll', positionSuggestions);
  }
});

onBeforeUnmount(() => {
  autocompleteElement.value?.removeEventListener('focusout', onBlur);
  if (process.client) {
    document.removeEventListener('scroll', positionSuggestions);
  }
});

function onBlur(event: FocusEvent) {
  if (!autocompleteElement.value) return emits('blur');

  const areChildrenFocused =
    autocompleteElement.value.contains(document.activeElement) ||
    // As the relatedTarget doesn't work well on all browsers make sure you add tabindex="0" to clickable child elements
    autocompleteElement.value.contains(event.relatedTarget as Node);

  if (areChildrenFocused) return;

  emits(emitSuggestionsOpened, false);
  emits('blur');
}

const activedescendant = ref<string>('');
const arrowCounter = ref(0);

function closeSuggestions() {
  emits(emitSuggestionsOpened, false);
  arrowCounter.value = -1;
}

function setResult(suggestion: AiSuggestion) {
  emits('suggestionPicked', suggestion);
  closeSuggestions();
}

function onArrowDown() {
  if (props.suggestions && props.suggestionsOpened) {
    if (arrowCounter.value < props.suggestions.length - 1) {
      arrowCounter.value++;
      setActiveDescendant();
    }
  } else {
    emits(emitSuggestionsOpened, true);
  }
}

function onArrowUp() {
  if (props.suggestionsOpened && arrowCounter.value > 0) {
    arrowCounter.value--;
    setActiveDescendant();
  }
}

function setActiveDescendant() {
  activedescendant.value = getId(arrowCounter.value);
  const desc = document.getElementById(activedescendant.value)
    ?.firstElementChild as HTMLElement;
  desc?.focus();
}

function getId(index: number) {
  return `item-index-${index}`;
}

function isSelected(i: number) {
  return i === arrowCounter.value;
}

function onIconClick() {
  if (props.iconClickable) {
    emits('icon-click');
  }
}

const positionSuggestions = throttle(() => {
  if (!process.client) return;
  if (!suggestionsElement.value || !autocompleteElement.value) return;

  const { height } = suggestionsElement.value.getBoundingClientRect();
  const { bottom } = autocompleteElement.value.getBoundingClientRect();
  const currentWindowHeight = window.innerHeight;

  suggestionsTop.value = bottom + height >= currentWindowHeight;
}, 200);

defineExpose({
  autocompleteElement,
  input,
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';

.AiAutocomplete {
  position: relative;
}

.AiAutocomplete-fieldIcon {
  fill: colors.$gold-800;

  &--clearable {
    cursor: pointer;
  }
}

.AiAutocomplete-suggestions {
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 100%;
  background-color: colors.$white;
  max-height: 50vh;
  overflow-y: auto;

  &--openTop {
    bottom: 100%;
    top: auto;
  }

  &::-webkit-scrollbar {
    width: constants.$inner-01;
    @include mixins.rem-fallback(height, 40);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$gold-700;
  }
}
</style>
