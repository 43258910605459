import { BookingBarPayload } from '~/domains/booking';
import { SearchDestination, SearchOrigin } from '~/domains/search';
import { SearchDestinationResultType } from '~/domains/graphql';
import { formatDate, FORMATS } from '~/helpers';

type TouchPoint = 'hotel' | 'search';

type GenerateUrl = (
  origin: SearchOrigin,
  destination: SearchDestination,
  staysData: BookingBarPayload,
  touchPoint?: TouchPoint,
) => string;

type UseFlightHotelUrlGenerator = () => {
  generateUrl: GenerateUrl;
};

export const useFlightHotelUrlGenerator: UseFlightHotelUrlGenerator = () => {
  const {
    public: { expediaSearchUrl },
  } = useRuntimeConfig();

  const generateUrl: GenerateUrl = (
    origin,
    destination,
    staysData,
    touchPoint = 'search',
  ) => {
    const roomsAndGuests = staysData.roomsAndGuests.length
      ? staysData.roomsAndGuests
      : [{ adults: 2, childrenAges: [] }];

    const { adults, childrenAges } = roomsAndGuests.reduce(
      (acc, { adults, childrenAges }) => {
        acc.adults += adults;
        acc.childrenAges.push(...childrenAges);

        return acc;
      },
      { adults: 0, childrenAges: [] },
    );
    const children = childrenAges.length;

    const checkIn = new Date(staysData.dateIn);
    const checkInDate = formatDate(checkIn, FORMATS.apiFormat);
    const checkOutDate = formatDate(
      new Date(checkIn.getTime() + staysData.nights * 24 * 60 * 60 * 1000),
      FORMATS.apiFormat,
    );

    const page = touchPoint === 'hotel' ? 'hotel/details' : 'search';

    const url = new URL(
      `${expediaSearchUrl}/go/package/${page}/FlightHotel/${checkInDate}/${checkOutDate}`,
    );

    // Origin and destination
    url.searchParams.append('FromAirport', origin.airportCode ?? '');
    url.searchParams.append('Destination', destination.text);
    if (destination.type === SearchDestinationResultType.Hotel) {
      url.searchParams.append('HotelID', destination.destinationId ?? '');
    } else {
      url.searchParams.append('DestinationID', destination.destinationId ?? '');
    }

    // Rooms and guests
    url.searchParams.append('NumRoom', roomsAndGuests.length.toString());
    url.searchParams.append('NumAdult', adults.toString());
    if (children > 0) {
      url.searchParams.append('NumChild', children.toString());
    }
    roomsAndGuests.forEach(({ adults, childrenAges }, index) => {
      const room = `Room${index + 1}`;
      url.searchParams.append(`NumAdult-${room}`, adults.toString());
      if (childrenAges.length > 0) {
        url.searchParams.append(
          `NumChild-${room}`,
          childrenAges.length.toString(),
        );
        childrenAges.forEach((age, childIndex) => {
          const child = `Child${childIndex + 1}Age`;
          url.searchParams.append(`${room}-${child}`, age.toString());
        });
      }
    });

    return url.toString();
  };

  return {
    generateUrl,
  };
};
