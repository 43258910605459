<template>
  <ai-autocomplete
    ref="autocomplete"
    v-model="search"
    v-model:suggestions-opened="showSuggestions"
    :disabled="disabled"
    :errors="errors"
    :hide-suggestions="hideSuggestions"
    :icon-name="hasSearch ? 'close' : 'pin-outline'"
    :inverse="inverse"
    :label="label"
    :message="inputHint"
    :placeholder="placeholder"
    :suggestion-component="suggestionComponent"
    :suggestions="suggestions"
    auto-complete="off"
    icon-clickable
    @focus="$emit('focus')"
    @input="$emit('input')"
    @icon-click="handleIconClick"
    @suggestion-picked="$emit('suggestion-picked', $event)"
    @keydown.enter="$emit('keydown.enter')">
    <template #suggestion-header>
      <div class="LocationAutocomplete-suggestionHeader">
        <ai-typo as="span" color="neutral-800" variant="legal-regular">
          {{ suggestionHelper }}
        </ai-typo>
      </div>
    </template>
    <template v-if="noResult" #suggestion-empty-placeholder>
      <div class="LocationAutocomplete-suggestionHeader">
        <ai-typo as="p" color="stratos-900" variant="legal-bold">
          {{ suggestionEmptyTitle }}
        </ai-typo>
        <ai-typo as="p" color="stratos-700" variant="legal-regular">
          {{ suggestionEmptyHelper }}
        </ai-typo>
      </div>
    </template>
  </ai-autocomplete>
</template>

<script lang="ts" setup>
import _debounce from 'lodash/debounce';

import type { SearchLocation } from '~/domains/search';
import type { AiSuggestion } from '~/domains/ux/molecules/AiInput/interfaces';
import type AiAutocomplete from '~/domains/ux/molecules/AiInput/AiAutocomplete.vue';

import type OriginSuggestion from './OriginSuggestion.vue';
import type DestinationSuggestion from './DestinationSuggestion.vue';

type allowedSuggestionComponents =
  | typeof OriginSuggestion
  | typeof DestinationSuggestion;

type Props = {
  debounce?: number;
  disabled?: boolean;
  errors?: string[];
  hideSuggestionHeader?: boolean;
  hideSuggestions?: boolean;
  inputHint?: string;
  inverse?: boolean;
  label: string;
  search: SearchLocation;
  placeholder: string;
  suggestionComponent: allowedSuggestionComponents;
  suggestionEmptyTitle: string;
  suggestionEmptyHelper: string;
  suggestionHelper: string;
  suggestions: AiSuggestion[] | null;
};
const props = withDefaults(defineProps<Props>(), {
  debounce: 300,
  errors: undefined,
  hideSuggestionHeader: false,
  hideSuggestions: false,
  inputHint: '',
  inverse: false,
  results: null,
});

type Emits = {
  (event: 'clear'): void;
  (event: 'focus'): void;
  (event: 'input'): void;
  (event: 'keydown.enter'): void;
  (event: 'debounced-input'): void;
  (event: 'suggestion-picked', value: AiSuggestion): void;
  (event: 'update:search', value: SearchLocation): void;
  (event: 'update:suggestions', value: unknown[] | null): void;
};
const emits = defineEmits<Emits>();

const showSuggestions = ref(false);
const autocomplete = ref<typeof AiAutocomplete | undefined>(undefined);

const search = computed({
  get() {
    return props.search.text;
  },
  set(value: string | SearchLocation) {
    if (typeof value === 'string') {
      emits('update:search', { text: value });
    } else {
      emits('update:search', value);
    }
  },
});

const hasSearch = computed(() => Boolean(search.value));

const noResult = computed(
  () =>
    hasSearch.value &&
    props.suggestions !== null &&
    props.suggestions.length === 0,
);

const suggestions = computed({
  get() {
    return props.suggestions ?? [];
  },
  set(value: AiSuggestion[] | null) {
    emits('update:suggestions', value);
  },
});

const debouncedQuery = _debounce((value: string | SearchLocation) => {
  const searchedText = typeof value === 'string' ? value : value.text;

  if (!searchedText) {
    emits('clear');
  }

  emits('debounced-input');
}, props.debounce + 10);

watch(search, debouncedQuery);

const handleIconClick = () => {
  hasSearch.value ? emits('clear') : autocomplete.value?.input?.input?.focus();
};

const input = computed(() => autocomplete.value?.input?.input);

defineExpose({ input });
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';

.LocationAutocomplete-suggestionHeader {
  padding: constants.$inner-03 constants.$inner-02;
  border-bottom: 1px solid colors.$gold-200;
}
</style>
